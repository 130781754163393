<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>الطلبات الجديدة</h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li>
                                            <router-link to="/my"
                                                >الرئيسية</router-link
                                            >
                                        </li>
                                        <li class="active">الطلبات الجديدة</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column col-lg-12">
                            <div class="messages-box">
                                <div class="title"><h3>قائمة الطلبات</h3></div>
                                <div class="inner-box">
                                    <article
                                        v-for="(booking, index) in bookings"
                                        :key="booking.id"
                                        class="message-box"
                                    >
                                        <div class="thumb-box">
                                            <figure class="thumb">
                                                <img
                                                    :src="
                                                        booking.client.image_url
                                                    "
                                                    alt=""
                                                />
                                            </figure>
                                        </div>
                                        <div class="content-box">
                                            <div class="name">
                                                {{ booking.client.name }}
                                            </div>
                                            <span class="date"
                                                ><i class="la la-calendar"></i>
                                                {{
                                                    formatedDate(
                                                        booking.created_at
                                                    )
                                                }}</span
                                            >
                                            <ul class="booking-info">
                                                <li>
                                                    <strong>العقار </strong>
                                                    {{ booking.title }}
                                                </li>

                                                <li>
                                                    <strong
                                                        >عنوان العقار
                                                    </strong>
                                                    {{
                                                        booking.property.address
                                                    }}
                                                </li>
                                                <li>
                                                    <strong>وقت الدخول </strong>
                                                    {{
                                                        booking.from_day +
                                                        " " +
                                                        (booking.from_period ==
                                                        1
                                                            ? "فترة أولى"
                                                            : booking.from_period ==
                                                              2
                                                            ? "فترة ثانية"
                                                            : "فترة ثالثة")
                                                    }}
                                                </li>
                                                <li>
                                                    <strong>وقت الخروج </strong>
                                                    {{
                                                        booking.to_day +
                                                        " " +
                                                        (booking.to_period == 1
                                                            ? "فترة أولى"
                                                            : booking.to_period ==
                                                              2
                                                            ? "فترة ثانية"
                                                            : "فترة ثالثة")
                                                    }}
                                                </li>
                                                <li>
                                                    <strong>حفلة </strong>
                                                    {{
                                                        booking.party == null
                                                            ? "لا يوجد"
                                                            : booking.party ==
                                                              "birthday"
                                                            ? "عيد ميلاد"
                                                            : booking.party ==
                                                              "wedding"
                                                            ? "زفاف"
                                                            : "أخرى"
                                                    }}
                                                </li>
                                                <li>
                                                    <strong>إيميل </strong
                                                    ><a
                                                        v-if="
                                                            booking.client.email
                                                        "
                                                        :href="
                                                            'mailto:' +
                                                            booking.client.email
                                                        "
                                                        >{{
                                                            booking.client.email
                                                        }}</a
                                                    >
                                                    <span v-else>لا يوجد</span>
                                                </li>
                                                <li>
                                                    <strong>هاتف </strong
                                                    ><a
                                                        v-if="
                                                            booking.client
                                                                .mobile
                                                        "
                                                        :href="
                                                            'tel:' +
                                                            booking.client
                                                                .mobile
                                                        "
                                                        >{{
                                                            booking.client
                                                                .mobile
                                                        }}</a
                                                    >
                                                    <span v-else>لا يوجد</span>
                                                </li>

                                                <li>
                                                    <strong>كود الدولة </strong>
                                                    {{
                                                        booking.client
                                                            .country_code
                                                    }}
                                                </li>

                                                <li>
                                                    <strong>الدولة </strong>
                                                    {{ booking.client.country }}
                                                </li>

                                                <li>
                                                    <strong>الإجمالى </strong>
                                                    {{ booking.total }}
                                                </li>

                                                <li>
                                                    <strong>الحالة </strong>
                                                    {{
                                                        booking.status ==
                                                        "authorized"
                                                            ? "تم تأكيد العميل للدفع"
                                                            : "تم الموافقة وسحب المبلغ"
                                                    }}
                                                </li>
                                            </ul>
                                            <div class="btn-box">
                                                <a
                                                    v-if="
                                                        booking.status ==
                                                        'authorized'
                                                    "
                                                    @click.prevent="
                                                        approve(
                                                            booking.id,
                                                            index
                                                        )
                                                    "
                                                    >موافقة
                                                    <i class="pe-7s-check"></i
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        reject(
                                                            booking.id,
                                                            index
                                                        )
                                                    "
                                                    >{{
                                                        booking.status ==
                                                        "authorized"
                                                            ? "رفض"
                                                            : "إلغاء"
                                                    }}
                                                    <i
                                                        class="pe-7s-close-circle"
                                                    ></i
                                                ></a>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div class="text-center mt-2">
                                    <button
                                        v-if="more"
                                        @click="getBookings(currentPage + 1)"
                                        class="btn btn-oval btn-primary edit text-white"
                                    >
                                        <i
                                            class="fa fa-plus"
                                            aria-hidden="true"
                                        ></i>
                                        المزيد
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    name: "my.bookings",
    data() {
        return {
            currentPage: 1,
            more: false,
            bookings: [],
        };
    },
    mounted() {
        this.getBookings();
    },
    methods: {
        getBookings(page = 1) {
            this.$axios.get("my/new-bookings?page=" + page).then((res) => {
                this.currentPage = res.data.bookings.current_page;
                this.more = !!res.data.bookings.next_page_url;
                this.bookings = this.bookings.concat(res.data.bookings.data);
            });
        },
        formatedDate(parse) {
            return moment(parse).locale("ar").calendar();
        },
        approve(booking_id, index) {
            this.$axios.put(`my/bookings/${booking_id}/approve`).then((res) => {
                this.bookings[index] = res.data.booking;
            });
        },
        reject(booking_id, index) {
            if (
                confirm(
                    "سيتم حذف الطلب وإبلاغ العميل بالرفض, هل أنت متأكد من الرفض ؟"
                )
            ) {
                this.$axios.delete(`my/bookings/${booking_id}`).then(() => {
                    this.bookings.splice(index, index + 1);
                });
            }
        },
    },
};
</script>